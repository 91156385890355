<template>
  <section class="booking-calendar">
    <div role="group" class="input-group" style="display: flex; flex-wrap: nowrap">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <v-icon name="calendar" style="height: 18px"></v-icon>
        </div>
      </div>
      <date-pick class="custom-datepick" v-model="dateSelected" :isDateDisabled="isPastDate"></date-pick>
    </div>
  </section>
</template>

<script>
  import DatePick from 'vue-date-pick';
  import moment from 'moment-timezone';

  moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);


  export default {
    name: "BookingCalendar.vue",
    components: {
      DatePick
    },
    data: () => ({
      date: null,
      weekdays: {
        type: Array,
        default: () => ([
          'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'
        ])
      },
    }),
    computed: {
      dateSelected: {
        get() {
          return this.$store.state.dateSelected
        },
        set(value) {

          sessionStorage.setItem('selectedDay', value)
      
               
          this.$store.commit('updateDate', value)

    
          this.$store.commit('resetScrollArrSlotsByDayPart')
          this.$store.commit('setUpDate', value)
          this.$store.commit('setBottomDate', value)

          this.$store.commit('setUpdateRenderSlots', false);
          this.$store.dispatch('ADD_SLOTS_AFTER_SCROLL', value)
          
      

          sessionStorage.removeItem("current-room-id");
          sessionStorage.removeItem('type-reserve');
          sessionStorage.removeItem('event-type')
          sessionStorage.removeItem("current-room-day");
          sessionStorage.removeItem("current-slot-id");
          this.$store.commit('setCurrentRoom', null);

          this.$store.commit('setPayObject', null)
          sessionStorage.removeItem("pay-object");



          this.$store.commit('setUpDate', value)
          this.$store.commit('setBottomDate', value)

          this.$store.commit('setDateHeader', value)


            // setTimeout(()=>{
            //   {
            //     let allButNode = document.querySelectorAll('.booking-table-button')
            //     let allBut = Array.from(allButNode);
            //     for (let but of allBut) {
            //       but.classList.remove('active');
            //       but.classList.remove('inactive');
            //     }
            //   }
            // },400)
            this.$store.commit('setCurentSoltIdVal', null);

        }
      }
    },
    methods: {
      isPastDate(date) {
        const currentDate = moment(this.$store.state.dateToday).add('days',-1);
        return currentDate >= date ;
      }
    },
    mounted() {

      // if(!sessionStorage.getItem('selectedDay')){
      //   sessionStorage.setItem('selectedDay', this.$store.state.dateToday)
      //   this.$store.commit('setUpDate', this.$store.state.dateToday)
      //   this.$store.commit('setBottomDate', this.$store.state.dateToday)
      // } else {
      //   this.$store.commit('updateDate',  sessionStorage.getItem('selectedDay'))
      //   this.$store.commit('setUpDate', sessionStorage.getItem('selectedDay'))
      //   this.$store.commit('setBottomDate', sessionStorage.getItem('selectedDay'))
      // }
      
    },

  }
</script>

<style lang="scss">
  .custom-datepick {
    width: 100px;
    border: 1px solid #ced4da;
    border-radius: 0 4px 4px 0;
    display: flex;

    & > input {
      width: 100%;
      padding: 0 !important;
      font-size: 16px;
      border: none;
      outline: none;
      border-radius: 0 4px 4px 0;
    }

    .vdpClearInput {
      display: none;
    }

    .input-group-text {
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
</style>
