<template>
  <section class="booking-room-select">
    <!--{{getAllRooms}}-->
    <!--{{allRooms}}-->
    <!--    <div v-for="room in getAllRooms" :key="room.id">-->
    <!--      {{room.id}}, {{room.name}}-->
    <!--    </div>-->

    <b-input-group>
      <template v-slot:prepend>
        <b-input-group-text>
          <v-icon name="home" style="height: 14px"></v-icon>
        </b-input-group-text>
      </template>
      <b-form-select v-model="room.selected" :options="options" @change="changeRoom"></b-form-select>
    </b-input-group>
  </section>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    name: "BookingRoomSelect.vue",
    data: () => ({
      // allRooms: [],
      room: {
        selected: 0,
        // options: [
        //   { value: 0, text: "Все" },
        //   { value: 1, text: "Красная" },
        //   { value: 2, text: "Оранжевая" },
        //   { value: 3, text: "Жёлтая" },
        //   { value: 4, text: "Зелёная" },
        //   { value: 5, text: "Голубая" },
        //   { value: 6, text: "Серая" },
        //   { value: 7, text: "Фиолетовая" },
        //   { value: 8, text: "Розовая" }
        // ]
      }
    }),
    computed:{
         options(){
          //  console.log(this.$store.getters.getRoomsNames);
           
           return this.$store.getters.getRoomsNames
        //     [
        //   { value: 0, text: "Все" },
        //   { value: 1, text: "Детская" },
        //   { value: 2, text: "Маленькая" },
        //   { value: 3, text: "Зелёная" },
        //   { value: 4, text: "Зелёная" },
        //   { value: 5, text: "Голубая" },
        //   { value: 6, text: "Серая" },
        //   { value: 7, text: "Фиолетовая" },
        //   { value: 8, text: "Розовая" }
        // ]
         }
    },
    // computed: mapGetters(['getAllRooms']),
    // mounted () {
    // this.allRooms.push(this.getAllRooms[0].img);
    // }
    mounted() {
      if(!sessionStorage.getItem('selectedRoom')){
         sessionStorage.setItem('selectedRoom', 0);
      }
      this.room.selected = sessionStorage.getItem('selectedRoom')
    },
    methods: {
      changeRoom(e) {
        sessionStorage.setItem("selectedRoom", e);
        this.$store.commit('SET_SELECTED_ROOM', e);
        // window.location.href = '/booking'
        sessionStorage.removeItem("current-room-id");
        sessionStorage.removeItem('type-reserve');
        sessionStorage.removeItem('event-type')
        sessionStorage.removeItem("current-room-day");
        sessionStorage.removeItem("current-slot-id");
        this.$store.commit('setCurrentRoom', null);

        this.$store.commit('setPayObject', null);
        sessionStorage.removeItem("pay-object");

        // {
        //   let bds = document.querySelector('.booking-day-slots')
        //   if (bds.offsetHeight == 0) {
        //       document.querySelector('.alert-for-room').classList.remove('hide')
        //   } else {
        //     document.querySelector('.alert-for-room').classList.add('hide')
        //   }
        // }
        

        // {
        //   let allButNode = document.querySelectorAll('.booking-table-button')
        //   let allBut = Array.from(allButNode);
        //   for (let but of allBut) {
        //     but.classList.remove('active');
        //     but.classList.remove('inactive');
        //   }
        // }
        
        //   setTimeout(()=>{
        //   {
        //     let allButNode = document.querySelectorAll('.booking-table-button')
        //     let allBut = Array.from(allButNode);
        //     for (let but of allBut) {
        //       but.classList.remove('active');
        //       but.classList.remove('inactive');
        //     }
        //   }
        // },200)
        this.$store.commit('setCurentSoltIdVal', null);

          let alert = document.querySelector('#booking-alert');
          let bds = document.querySelector('.booking-table');
          // console.log(bds);
          // console.log('Client ' + bds.clientHeight);
          // console.log('Offset ' + bds.offsetHeight);
          // console.log('Scroll ' + bds.scrollHeight);
          // alert.style.display = "none";
          //   if (bds.clientHeight <= 20) {
          //     alert.style.display = "block";
          //   }

        // this.$store.commit('resetScrollArrSlotsByDayPart')
        // this.$store.commit('setUpDate', this.$store.getters.getCurrentDate)
        // this.$store.commit('setBottomDate', this.$store.getters.getCurrentDate)

        // this.$store.commit("SLOTS_MUTATION", this.$store.getters.getCurrentDate);
        // this.$store.commit("setScrollArrSlotsByDayPart", { date: this.$store.getters.getCurrentDate, arr: this.$store.getters.getsortedSlotsByDayPart });

        // let scrollArea = document.querySelector('.scroll-area')
        // scrollArea.scrollTop = 5;
        this.$store.commit('setAllowScroll', false)
      }
    }
  };
</script>

<style scoped>
</style>