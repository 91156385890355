import { render, staticRenderFns } from "./BookingRoomSelect.vue?vue&type=template&id=63741aac&scoped=true"
import script from "./BookingRoomSelect.vue?vue&type=script&lang=js"
export * from "./BookingRoomSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63741aac",
  null
  
)

export default component.exports