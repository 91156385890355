<template>
  <section class="booking-day-slots">
    <!-- <pre style="display: none;">
      {{getScrollArrSlotsByDayPart}}
      {{getsortedSlots}}
    </pre> -->
    <!--        Выбраная комната: {{this.$store.state.selectedRoom}}<br>-->
    <!--        Выбраный период: {{this.$store.state.slots.period}}<br>-->
    <!-- <pre>{{getScrollArrSlotsByDayPart}}</pre> -->
    <vue-custom-scrollbar
      class="scroll-area"
      :settings="settings"
      @ps-scroll-y="scrollHandle"
      @ps-y-reach-start="scrollStart"
      @ps-y-reach-end="scrollEnd"
      @ps-scroll-up="scrollUp"
      @ps-scroll-down="scrollDown"
    >
      <div class="scroll-inner-block">
        <section
          class="booking-table"
          v-if="$store.state.dateSelected === $store.getters.getCurrentDate"
        >

          <!--Отрисовка всех слотов-->
          <div
            :class="[scrollItemDay[0].morning.length === 0 && scrollItemDay[1].day.length === 0 && scrollItemDay[2].evening.length === 0 ? 'hiding-row booking-table__inner' : 'booking-table__inner',  $moment(i).format('YYYY-MM-DD') === $moment($store.state.dateSelected).format('YYYY-MM-DD') ? 'today__slot-row' : 'notoday__slot-row']"
            v-for="(scrollItemDay, i ) in getScrollArrSlotsByDayPart"
            :key="i"
          >
 
            <!-- <pre>
            {{scrollItemDay}}
          </pre> -->

            <!-- v-if="$store.state.dateToday !== $moment(i).format('YYYY-MM-DD')" -->
            <!-- [scrollItemDay[0].morning.length === 0 && scrollItemDay[1].day.length === 0 && scrollItemDay[2].evening.length === 0 ? 'slots__day--none' : '', 'slots__day'] -->
            <!-- v-if="$moment(i).format('YYYY-MM-DD') !== $moment($store.state.dateSelected).format('YYYY-MM-DD') && scrollItemDay[0].morning.length !== 0 || scrollItemDay[1].day.length !== 0 || scrollItemDay[2].evening.length !== 0 " -->

            <!-- <pre>{{scrollItemDay}}</pre>
          <div class="empty"  v-if="scrollItemDay[0].morning.length === 0 || scrollItemDay[1].day.length === 0 || scrollItemDay[2].evening.length === 0">

          </div> -->

            <h2
              :class=" [
                $moment(i).format('YYYY-MM-DD') !== $moment($store.state.dateSelected).format('YYYY-MM-DD') && scrollItemDay[0].morning.length === 0 && scrollItemDay[1].day.length === 0 && scrollItemDay[2].evening.length === 0 ? 'slots__day--none' : '', 'slots__day', 
                $store.getters.getTypeScroll === 'up' ? 'slots__day--hide' : '',
                $moment(i).format('YYYY-MM-DD') === $moment($store.state.dateSelected).format('YYYY-MM-DD') ? 'today__heading' : ''
                ] "
              :data-time="$moment(i).format('YYYY-MM-DD')"
            >{{$moment(i).format('dddd | D MMMM  YYYY р')  }}</h2>

            <template v-if="scrollItemDay[0].morning.length !== 0 || scrollItemDay[1].day.length !== 0 || scrollItemDay[2].evening.length !== 0">
              <b-row
                class="booking-table-row"
                v-for="(dayParts, dayPartsIndex) in scrollItemDay"
                v-if="selectedRoom === 0"
                :key="dayPartsIndex"
              >
                <b-col
                  sm="12"
                  v-if="Object.keys(getsortedSlotsGroup).length >= 1"
                  v-for="(dayPart,dayPartIndex) in dayParts"
                  class="booking-table-row_inner"
                >

                  <b-badge
                    style="line-height:1"
                    variant="light"
                  >

                    <span v-if="dayPart.length >= 1">

                      <span
                        v-if="dayPartIndex === 'morning' "
                        class="badge-morning"
                      >
                        <v-icon
                          name="sunrise"
                          class="badge-icon"
                        ></v-icon>
                        <span class="badge-text">Ранок</span>
                      </span>
                    </span>
                    <span
                      v-else
                      style="display: none"
                    ></span>

                    <span v-if="dayPart.length >= 1">
                      <span
                        v-if="dayPartIndex ===  'day' "
                        class="badge-day"
                      >
                        <v-icon
                          name="sun"
                          class="badge-icon"
                        ></v-icon>
                        <span class="badge-text">День</span>
                      </span>
                    </span>
                    <span
                      v-else
                      style="display: none"
                    ></span>

                    <span v-if="dayPart.length >= 1">
                      <span
                        v-if="dayPartIndex === 'evening'"
                        class="badge-evening"
                      >
                        <v-icon
                          name="sunset"
                          class="badge-icon"
                        ></v-icon>
                        <span class="badge-text">Вечір</span>
                      </span>
                    </span>
                    <span
                      v-else
                      style="display: none"
                    ></span>
                  </b-badge>
                </b-col>
                <div v-else></div>

                <div
                  v-for="(dayPart,dayPartIndex) in dayParts"
                  class="col-sm-12"
                >
                  <template v-for="(days,daysIndex) in dayPart">
                    <b-row
                      class="booking-table-row slots"
                      v-for="(slots, key) in days"
                    >
                      <!-- <pre>
                    {{key}}
                  </pre> -->
                      <b-col
                        sm="2"
                        class="booking-table-row-1 time booking-table-row__inner"
                        style="order: -10"
                      >
                        <span>
                          {{key}}
                        </span>

                      </b-col>
                      <template v-for="slot in slots">
                        <!-- <pre>
  {{$store.getters.getCurrentRoom}}
  {{slot}}
</pre> -->
                        <!-- @mouseleave="hover = false" -->
                        
                        <b-button
                          :data-slot-id="slot.id"
                          type="button"
                          variant="outline booking-table-button"
                          :class="[parseInt(currentSlotId) === parseInt(slot.id) ? 'inactive active' : parseInt(currentSlotId) ? 'inactive' : '', ` roomid-${slot.roomId}`]"
                          @click="SelectRoom(slot, slot.id,slot.roomId,slot.period, moment(slot.startDate).format('ddd, D MMMM YYYY'))"
                          :data-date-start="moment(slot.start).format('HH:mm')"
                          :data-date-end="moment(slot.end).format('HH:mm')"
                          :data-selectedroom="selectedRoom"
                          @mouseover="hoverActive"
                          @mouseleave="hoverNoActive"
                        >
                          <div class="booking-table-button-name">
                            <svg
                              data-v-15b2649e=""
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-home"
                              style="height: 14px;"
                            >
                              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                              <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            {{getAllRooms[slot.roomId-1].name}}
                          </div>
                          <div class="booking-table-button-desc">
                            <v-icon name="tag"></v-icon>
                            {{getAllRooms[slot.roomId-1].price}} грн/год
                            <v-icon name="users"></v-icon>
                            {{getAllRooms[slot.roomId-1].capacity}}
                          </div>
                        </b-button>
                      </template>
                    </b-row>
                  </template>
                </div>
              </b-row>
            </template>

            <!-- Отрисовка выбранных комнат-->
            <template v-if="scrollItemDay[0].morning.length !== 0 || scrollItemDay[1].day.length !== 0 || scrollItemDay[2].evening.length !== 0">
              <b-row
                class="booking-table-row"
                v-for="(dayParts, dayPartsIndex) in scrollItemDay"
                v-if="selectedRoom !== 0"
              >
                <!-- <pre>
              {{dayParts}}
            </pre> -->

                <template v-for="(dayPart,dayPartIndex) in dayParts">
                  <!-- <pre>{{dayPartIndex}}</pre><br>
<pre>{{dayPart.length}}</pre> -->

                  <template v-for="(days,daysIndex) in dayPart">

                    <div
                      class="col-sm-12 label"
                      v-for="(slots, key) in days"
                      v-if="slots.some(x => x.roomId == selectedRoom)"
                    >

                      <!-- <template v-if="slots[0].sort < 12"> -->

                      <b-badge
                        v-if="dayPart.length >= 1"
                        variant="light"
                      >

                        <span
                          v-if="dayPartIndex === 'morning' "
                          class="badge-morning"
                        >
                          <v-icon
                            name="sunrise"
                            class="badge-icon"
                          ></v-icon>
                          <span class="badge-text">Ранок</span>
                        </span>
                      </b-badge>
                      <!-- </template> -->

                      <!-- <template v-else-if="slots[0].sort >= 12 && slots[0].sort < 18"> -->
                      <b-badge
                        v-if="dayPart.length >= 1"
                        variant="light"
                      >
                        <span
                          v-if="dayPartIndex ===  'day'"
                          class="badge-day"
                        >
                          <v-icon
                            name="sun"
                            class="badge-icon"
                          ></v-icon>
                          <span class="badge-text">День</span>
                        </span>
                      </b-badge>
                      <!-- </template> -->

                      <!-- <template v-else-if="slots[0].sort >= 18"> -->
                      <b-badge
                        v-if="dayPart.length >= 1"
                        variant="light"
                      >
                        <span
                          v-if="dayPartIndex === 'evening'"
                          class="badge-day"
                        >
                          <v-icon
                            name="sunset"
                            class="badge-icon"
                          ></v-icon>
                          <span class="badge-text">Вечір</span>
                        </span>
                      </b-badge>
                      <!-- </template> -->
                    </div>
                    <!-- <b-alert
            v-else
            show
            variant="warning"
          >
            На жаль, вибрані кімнати недоступні для бронювання у вказаний день. Спробуйте обрати іншу кімнату або змінити дату та тривалість бронювання.
          </b-alert> -->

                  </template>

                </template>

                <div
                  v-for="(dayPart,dayPartIndex) in dayParts"
                  class="col-sm-12  booking-table-row_inner"
                >

                  <template v-for="(days,daysIndex) in dayPart">

                    <b-row
                      class="booking-table-row slots"
                      v-for="(slots, key) in days"
                      v-if="slots.some(x => x.roomId == selectedRoom)"
                    >
                      <b-col
                        sm="2"
                        class="booking-table-row-1 time"
                        style="order: -10"
                      >
                        <span>
                          {{key}}
                        </span>
                      </b-col>
                      <template v-for="slot in slots">

                        <!-- <b-button
                :data-slot-id="slot.id"
                type="button"
                variant="outline booking-table-button"
                :class="[parseInt(currentSlotId) === parseInt(slot.id) ? 'inactive active' : parseInt(currentSlotId) ? 'inactive' : '', ` roomid-${slot.roomId}`]"
                @click="SelectRoom(slot, slot.id,slot.roomId,slot.period, moment(slot.startDate).format('D MMMM YYYY'))"
                :data-date-start="moment(slot.start).format('HH:mm')"
                :data-date-end="moment(slot.end).format('HH:mm')"
                :data-selectedroom="selectedRoom"
                @mouseover="hoverActive"
                @mouseleave="hoverNoActive"
            >
              <div class="booking-table-button-name">
                {{getAllRooms[slot.roomId-1].name}} кімната
              </div>
              <div class="booking-table-button-desc">
                <v-icon name="tag"></v-icon>
                {{getAllRooms[slot.roomId-1].price}} грн/год
                <v-icon name="users"></v-icon>
                {{getAllRooms[slot.roomId-1].capacity}}
              </div>
            </b-button> -->

                        <b-button
                          v-if="slot.roomId == selectedRoom"
                          type="button"
                          variant="outline booking-table-button"
                          :class="[parseInt(currentSlotId) === parseInt(slot.id) ? 'inactive active' : parseInt(currentSlotId) ? 'inactive' : '', ` roomid-${slot.roomId}`]"
                          @click="SelectRoom(slot, slot.id,slot.roomId,slot.period, moment(slot.startDate).format('ddd, D MMMM YYYY'))"
                          :data-date-start="moment(slot.start).format('HH:mm')"
                          :data-date-end="moment(slot.end).format('HH:mm')"
                          :data-selectedroom="selectedRoom"
                          style="order: -1"
                          @mouseover="hoverActive"
                          @mouseleave="hoverNoActive"
                          :data-slot-id="slot.id"
                        >
                          <div class="booking-table-button-name">
                            <svg
                              data-v-15b2649e=""
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-home"
                              style="height: 14px;"
                            >
                              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                              <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            {{getAllRooms[slot.roomId-1].name}}
                          </div>
                          <div class="booking-table-button-desc">
                            <v-icon name="tag"></v-icon>
                            {{getAllRooms[slot.roomId-1].price}} грн/год
                            <v-icon name="users"></v-icon>
                            {{getAllRooms[slot.roomId-1].capacity}}
                          </div>
                        </b-button>

                        <!-- D MMMM YYYY -->
                        <b-button
                          v-else="slot.roomId != selectedRoom"
                          type="button"
                          variant="outline booking-table-button"
                          :class="[parseInt(currentSlotId) === parseInt(slot.id) ? 'inactive active' : parseInt(currentSlotId) ? 'inactive' : '', ` roomid-${slot.roomId}`]"
                          @click="SelectRoom(slot, slot.id,slot.roomId,slot.period, moment(slot.startDate).format('ddd, D MMMM YYYY'))"
                          :data-date-start="moment(slot.start).format('HH:mm')"
                          :data-date-end="moment(slot.end).format('HH:mm')"
                          :data-selectedroom="selectedRoom"
                          @mouseover="hoverActive"
                          @mouseleave="hoverNoActive"
                          :data-slot-id="slot.id"
                        >
                          <div class="booking-table-button-name">
                            <svg
                              data-v-15b2649e=""
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-home"
                              style="height: 14px;"
                            >
                              <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                              <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            {{getAllRooms[slot.roomId-1].name}}
                          </div>
                          <div class="booking-table-button-desc">
                            <v-icon name="tag"></v-icon>
                            {{getAllRooms[slot.roomId-1].price}} грн/год
                            <v-icon name="users"></v-icon>
                            {{getAllRooms[slot.roomId-1].capacity}}
                          </div>
                        </b-button>

                      </template>
                    </b-row>

                  </template>
                </div>
                <!-- НЕТ СВОБОДНЫХ КОМНАТ -->
              </b-row>
            </template>
     
        

            <!-- HET KOMHAT -->
                   <!-- <section v-for="(dayParts, dayPartsIndex) in getsortedSlotsByDayPart" v-if="selectedRoom !== 0">

                     <template v-for="(dayPart,dayPartIndex) in dayParts">

                       <template v-for="(days,daysIndex) in dayPart">

                         <div class="col-sm-12 label"   v-for="(slots, key) in days" v-if="slots.some(x => x.roomId == selectedRoom)">   
                         </div>
                       </template>

                     </template>

                     <div v-for="(dayPart,dayPartIndex) in dayParts" class="col-sm-12">
                       <template v-for="(days,daysIndex) in dayPart">
                         <template class="booking-table-row slots" v-for="(slots, key) in days">
                           <div>{{slots}}</div>
                         </template>

                       </template>
                     </div>
                   </section> -->

          <b-alert
            v-if="$moment(i).format('YYYY-MM-DD') === $moment($store.state.dateSelected).format('YYYY-MM-DD') && scrollItemDay[0].morning.length === 0 && scrollItemDay[1].day.length === 0 && scrollItemDay[2].evening.length === 0"
            show
            variant="warning"
            class="warning-message error1"
          >
          
            На жаль, вибрані кімнати недоступні для бронювання у вказаний день. Спробуйте обрати іншу кімнату або змінити дату та тривалість бронювання.
          </b-alert>

          

            <b-alert
            v-else-if="$moment(i).format('YYYY-MM-DD') === $moment($store.state.dateSelected).format('YYYY-MM-DD') && (scrollItemDay[0].morning.length !== 0 || scrollItemDay[1].day.length !== 0 || scrollItemDay[2].evening.length !== 0)"
            show
            variant="warning"
            class="warning-message error2"
          >
            На жаль, вибрані кімнати недоступні для бронювання у вказаний день. Спробуйте обрати іншу кімнату або змінити дату та тривалість бронювання.
          </b-alert>


     

          

          </div>

          

          <div style="display:none;">{{getsortedSlotsGroup}}</div>
  
          <!-- <b-alert
            v-if="Object.keys(getsortedSlotsGroup).length < 1"
            show
            variant="warning"
          >
            На жаль, вибрані кімнати недоступні для бронювання у вказаний день. Спробуйте обрати іншу кімнату або змінити дату та тривалість бронювання.
          </b-alert> -->
        </section>

        <section v-else>
          <b-alert
            show
            variant="warning"
            classs="error3"
          >
            На жаль, вибрані кімнати недоступні для бронювання у вказаний день. Спробуйте обрати іншу кімнату або змінити дату та тривалість бронювання.
          </b-alert>
        </section>
      </div>

    </vue-custom-scrollbar>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  components: {
    vueCustomScrollbar
  },
  name: "BookingDaySlots",
  data: () => ({
    falseSlots: {},
    curentSoltIdVal: null,
    firstLoad: true,
    hover: false,
    payObj: null,

    settings: {
      maxScrollbarLength: 60
    }
  }),
  computed: {
    ...mapGetters([
      "getAllRooms",
      "getFrontSlots",
      "getsortedSlots",
      "getsortedSlotsByRoom",
      "getsortedSlotsGroup",
      "getsortedSlotsByDayPart",
      "getsortedSlotsByDayPartByRoom",
      "getScrollArrSlotsByDayPart",
      "getIsLoadSlots",
      "getUpDate",
      "getBottomDate",
      "getUpdateRenderSlots"
    ]),
    selectedRoom() {

      return this.$store.state.selectedRoom;
    },
    updatePeriod: {
      get () {
        return this.$store.state.dateSelected;
      },
      set (value) {
        this.$store.commit("updateDate", value);
      }
    },
    currentSlotId () {

      // if (this.curentSoltIdVal) {
      //   return this.curentSoltIdVal
      // } else {
      // if (this.$store.getters.getCurentSoltIdVal) {
      //   return this.$store.getters.getCurentSoltIdVal
      // } 
      return this.$store.getters.getCurentSoltIdVal
      // else {
      //   return sessionStorage.getItem('current-slot-id')
      // }

    }
  },
  methods: {
    hoverActive (e) {
      let allButNode = document.querySelectorAll('.booking-table-button')
      for (let but of allButNode) {
        let currentRoomButtonId = but.getAttribute('data-slot-id');
        if (currentRoomButtonId !== sessionStorage.getItem('current-slot-id')) {
          but.classList.remove('active')
          but.classList.add('inactive')
        }
      }
      e.target.classList.add('active')
    },
    hoverNoActive (e) {
      let allButNode = document.querySelectorAll('.booking-table-button')
      for (let but of allButNode) {
        if (!sessionStorage.getItem('current-slot-id')) {
          but.classList.remove('active')
          but.classList.remove('inactive')
        }
      }
      let currentRoomButtonId = e.target.getAttribute('data-slot-id');
      if (currentRoomButtonId !== sessionStorage.getItem('current-slot-id')) {
        e.target.classList.remove('active')
      }

    },
    scrollUp () {

      if (this.getUpdateRenderSlots) {
        this.firstLoad = false
        // this.$store.commit('setAllowScroll', true)
      } else {
        this.firstLoad = true
        // this.$store.commit('setAllowScroll', false)
      }
    },
    scrollDown (e) {
      let scrollTop = e.target.scrollTop
      let scrollBlockHeight = document.querySelector('.scroll-inner-block').offsetHeight


      if (scrollTop + 600 > scrollBlockHeight) {
        if (this.getIsLoadSlots) {
          let bottomDate = this.$moment(this.getBottomDate).add('days', 1).format('YYYY-MM-DD')
          this.$store.commit('setBottomDate', bottomDate)
          this.$store.dispatch('ADD_SLOTS_AFTER_SCROLL', bottomDate)
          this.$store.commit('setTypeScroll', 'down');
        }
      }

    },
    scrollStart (e) {

      e.target.scrollTop = 1

      let isBefore = this.$moment(this.$store.state.dateToday).isBefore(this.$moment(this.$store.getters.getUpDate));
      if (isBefore) {


        if (!this.firstLoad && this.$store.getters.getAllowScroll) {
          if (this.getIsLoadSlots) {
            let upDate = this.$moment(this.getUpDate).add('days', -1).format('YYYY-MM-DD');
            this.$store.commit('setUpDate', upDate)
            this.$store.dispatch('ADD_SLOTS_AFTER_SCROLL', upDate)

            // this.$store.commit('setDateHeader', this.$moment(upDate).format('YYYY-MM-DD'));

            this.$store.commit('setTypeScroll', 'up');

          }
        }
      }


      this.$store.commit('setAllowScroll', true)


    },
    scrollEnd (e) {



      if (this.getIsLoadSlots) {
        let bottomDate = this.$moment(this.getBottomDate).add('days', 1).format('YYYY-MM-DD')
        this.$store.commit('setBottomDate', bottomDate)
        this.$store.dispatch('ADD_SLOTS_AFTER_SCROLL', bottomDate)
        this.$store.commit('setTypeScroll', 'down');
      }
    },
    scrollHandle (e) {


      let datesHeaders = document.querySelectorAll('.slots__day')
      for (let dateHeader of datesHeaders) {

        if (e.target.scrollTop + 50 >= dateHeader.offsetTop) {
          dateHeader.classList.add('slots__day--hide')
          if (!dateHeader.classList.contains('slots__day--none')) {


            if (dateHeader.classList.contains('today__heading')) {
              this.$store.commit('setDateHeader', this.$moment(dateHeader.getAttribute('data-time')).format('YYYY-MM-DD'));
            }
            else if (
              (dateHeader.parentNode.classList.contains('notoday__slot-row') || dateHeader.parentNode.classList.contains('today__slot-row')) &&
              !dateHeader.parentNode.classList.contains('error-message__show')
            ) {
              this.$store.commit('setDateHeader', this.$moment(dateHeader.getAttribute('data-time')).format('YYYY-MM-DD'));
            }

          }
        } else {
          dateHeader.classList.remove('slots__day--hide')
        }
      }

    },
    SelectRoom (SLOT, slotId, roomId, roomPeriod, roomDay) {
      let getRoom = this.$store.getters.getRoom(parseInt(roomId));
      let priceAmount = 0;
      let eventType = null;
      let discount = this.$store.getters.getUserData?.discount;
      const period = this.$store.getters.getPeriod;

      if (this.$store.getters.getIsAdmin && this.$store.getters.getUserDiscount) {
        discount = this.$store.getters.getUserDiscount;
      }

      if (getRoom.event_types.length > 0) {
        priceAmount = getRoom.event_types[0].price
        eventType = getRoom.event_types[0].id
      } else {
        priceAmount = getRoom.price
      }
       discount = (!isNaN(parseFloat(discount))) ? parseFloat(discount) : 0;

      this.payObj = {
        "date_from": this.$moment(SLOT.startDate).format(),
        "date_to": this.$moment(SLOT.endDate).format(),
        "amount": priceAmount,
        "pay_type": parseInt(sessionStorage.getItem('type-reserve')), //1-картой, 2-наличными
        "room_id": SLOT.roomId,
        "event_type": eventType,
        "discount": discount,
        "period": period
      }

      let currentRoom = {
        slotId: slotId,
        id: parseInt(roomId),
        period: roomPeriod,
        day: roomDay
      };

      let allButNode = document.querySelectorAll('.booking-table-button')
      let allBut = Array.from(allButNode);
      if (!event.target.parentNode.classList.contains('active')) {
        for (let but of allBut) {
          if (but.classList.contains('actived')) {
            but.classList.remove('actived');
          }
          if (but.classList.contains('active')) {
            // but.classList.remove('active');
          }
          but.classList.add('inactive');
        }

        event.target.parentNode.classList.add('active')

        this.$store.commit("setCurrentRoom", currentRoom);
        this.$store.commit('setCurentSoltIdVal', slotId);
        this.$store.commit('setPayObject', this.payObj)
        this.$store.dispatch('setBookingAmount', priceAmount) 

        sessionStorage.setItem("current-slot-id", slotId);
        sessionStorage.setItem("current-room-id", currentRoom.id);
        sessionStorage.setItem("current-room-period", roomPeriod);
        sessionStorage.setItem("current-room-day", roomDay);
        sessionStorage.setItem("pay-object", JSON.stringify(this.payObj));
      } else {
        for (let but of allBut) {
          // but.classList.add('actived');
          but.classList.remove('active');
          but.classList.remove('inactive');
        }

        event.target.parentNode.classList.remove('active')

        this.$store.commit("setCurrentRoom", null);
        this.$store.commit('setCurentSoltIdVal', null);
        this.$store.commit('setPayObject', null);

        sessionStorage.removeItem("current-room-id");
        sessionStorage.removeItem("current-room-period");
        sessionStorage.removeItem("current-room-day");
        sessionStorage.removeItem("current-slot-id");
        sessionStorage.removeItem('type-reserve')
        sessionStorage.removeItem('event-type');
        sessionStorage.removeItem("pay-object");

      }
    }
  },
  mounted () {
    if(sessionStorage.getItem('current-slot-id')){
      this.$store.commit('setCurentSoltIdVal', sessionStorage.getItem('current-slot-id'));
    }

    if (sessionStorage.getItem("pay-object")) {
      this.payObj = JSON.parse(sessionStorage.getItem("pay-object"))
      this.$store.commit("setPayObject", this.payObj);
    }

    this.$store.dispatch('GET_SLOTS_API', sessionStorage.getItem('selectedDay'));
    this.$store.dispatch("GET_USER_DATA", "me");
  },
  updated () {
    let dom1 = document.querySelectorAll('.booking-table__inner');

    for (let el1 of dom1) {
      let p = [];
      for (let el2 of el1.querySelectorAll('.booking-table-row')) {

        if (el2.querySelector('.booking-table-row_inner')) {
          if (el2.querySelector('.booking-table-row_inner').children.length === 0) {
            p.push('false')
          } else {
            p.push('true')
          }
        }
      }

      function isFalse (element, index, array) {
        return element === 'false';
      }

      if (p.length !== 0) {
        if (p.every(isFalse)) {
          el1.classList.remove('error-message__hide')
          el1.classList.add('error-message__show')
        } else {
          el1.classList.remove('error-message__show')
          el1.classList.add('error-message__hide')
        }
      }
    }
  }
};
</script>

<style lang="scss">
$header-height: 70px;
$footer-height: 60px;
$filter-height: 60px;
.booking-table-button {
  height: 50px;
  overflow: hidden;
  .booking-table-button-name,
  .booking-table-button-desc {
    white-space: nowrap;
  }
  .booking-table-button-name {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.booking-table-row-1 {
  font-size: 14px !important;
  & > span {
    width: 45px;
    overflow: hidden;
    height: 20px;
  }
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  max-height: calc(
    100vh - #{$header-height} - #{$footer-height} - #{$filter-height} - 1rem
  );
  padding: 0 1rem 1rem;
  padding-left: 0;
}

.booking-table {
  &.scrolled {
    overflow-y: scroll;
    max-height: calc(
      100vh - #{$header-height} - #{$footer-height} - #{$filter-height} - 1rem
    );
    overflow-x: hidden;
    width: 100%;
    padding: 0 1rem 2rem;
  }
}

.booking-table {
  & .badge {
    padding: 0;
    color: #999;

    &.badge-light {
      & .badge-day,
      & .badge-morning,
      & .badge-evening {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-icon {
      height: 12px;
      width: 12px;
      flex: 1;
      margin-left: 5px;
    }

    &-text {
      margin: 6px;
    }
  }

  &-row {
    margin-bottom: 6px;

    &-1 {
      width: 110px;
      flex-basis: 110px;
      font-size: 11px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-button {
    flex: 1;
    text-align: left;
    /* margin-right: 4px; */

    &-name {
      font-size: 14px;
    }

    &-desc {
      font-size: 12px;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .col {
    padding: 0 1px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
 {
  opacity: 0;
}

.booking-table-row {
  & > .label {
    display: none;

    &:first-of-type {
      display: block;
    }
  }
}
.slots__day {
  padding-top: 20px;
  font-size: 24px;
  font-weight: bold;
  /* transition: 0.4s ease; */
  &:first-letter {
    text-transform: uppercase;
  }
  &--hide {
    opacity: 0;
    height: 0;
    padding-top: 0;
  }
}
// .booking-table {
//   /* & > div:first-child {
//     margin-top: -60px;
//   } */
// }
// .hiding-row {
//   /* display: none */
// }
.slots__day--none {
  display: none;
}
.warning-slots {
  display: none;
}
.error-message {
  &__hide {
    .warning-message {
      display: none;
    }
  }
  &__show {
    .slots__day:not(.today__heading) {
      /* display: none; */
      opacity: 0;
      height: 0;
      padding-top: 0;
    }
    .warning-message {
      display: block;
    }
    &.notoday__slot-row {
      display: none;
      /* opacity: 0;
      height: 0;
      padding-top: 0; */
    }
  }
}
</style>
