<template>
  <section class="cart">

    <template v-if="$store.getters.getCurrentRoom">
      <CartRoomInfo :room="room" :eventType="eventType" :eventTypePrice="eventTypePrice"></CartRoomInfo>
      <div class="booking_info-wrap">
        <div style="font-size: 16px; font-weight: bold; padding:0 0 .5rem 0;">Інформація про бронювання</div>
        <div class="booking_info">
          <div class="booking_info-block">
            <div class="booking_info-block-name">
              Дата:
            </div>
            <div class="booking_info-block-value" v-html="dayRender"></div>
          </div>
          <div class="booking_info-block">
            <div class="booking_info-block-name">
              Час:
            </div>
            <div class="booking_info-block-value">
              {{ $store.getters.getCurrentRoom.period }}
            </div>
          </div>
        </div>

        <b-container class="users-list">
          <Dropdown
              :options="usersDropdownOptions"
              v-if="$store.getters.getIsAdmin"
              v-on:selected="selectUserForBooking"
              v-on:filter="getUserDropdownValues"
              :disabled="false"
              name="users"
              placeholder="Оберіть користувача для бронювання"
              label="Користувач">
          </Dropdown>
        </b-container>

        <b-form-group v-if="eventType.options.length>0">
          <b-form-radio-group
            v-model="eventType.selected"
            :options="eventType.options"
            name="radios-event-type"
            @change="changeEventType"
            value-field="id"
            text-field="name"
            stacked
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group>
          <b-form-radio-group
            v-model="cart.selected"
            :options="cart.options"
            name="radios-stacked"
            @change="changeTypeReserve"
            stacked
          ></b-form-radio-group>
        </b-form-group>

        <b-container class="field-comment">
             <label for="comment">Коментар</label>
             <b-form-textarea
                 v-model="cart.comment"
                 id="comment"
                 @change="changeComment"
                 no-resize
             ></b-form-textarea>
         </b-container>

        <b-row>
          <b-col style="min-width: auto;">
            <small><b>Разом:</b></small>
            <div style="font-size: 18px; font-weight: bold;">
              {{ bookingPriceTotal }}
            </div>
          </b-col>

          <b-col style="min-width: auto;">
            <b-button
              @click="reserve"
              :disabled="!isOrderPossible"
              variant="primary"
              style="margin-top: 9px"
            >Забронювати</b-button>
          </b-col>
        </b-row>
      </div>

    </template>
    <template v-else>
      <img style="width: 100%" src="/img/rooms/noroom.svg" alt="">
    </template>
  </section>
</template>

<script>
import Dropdown from "@/components/Dropdown.vue";
import CartRoomInfo from "@/views/components/CartRoomInfo.vue"

export default {
  name: "Cart",
  components: {
    Dropdown,
    CartRoomInfo
  },
  data () {
    return {
      userForBooking: {name: null, id: null, discount: 0},
      cart: {
        selected: '',
        options: [
          { text: 'Сплатити онлайн', value: '1', disabled: this.$store.getters.getIsAdmin },
          { text: 'Сплатити пізніше', value: '2' }
        ],
        comment: ''
      }
    }
  },
  computed: {
    dayRender() {
    if (!this.$store.getters.getCurrentRoom) return null;
      return this.$store.getters.getCurrentRoom?.day
    },
    room() {
      if (!this.$store.getters.getCurrentRoom) return null;
        return this.$store.getters.getRoom(this.$store.getters.getCurrentRoom?.id);
    },
    eventType() {
      if (!this.$store.getters.getCurrentRoom) return null;
      let room = this.room;
      let eventType =  {
        selected: '',
        options: room.event_types
      };

      if (eventType.options.length > 0) {
        if (sessionStorage.getItem('event-type')) {
          eventType.selected = sessionStorage.getItem('event-type')
        } else {
          eventType.selected = room.event_types[0].id
        }
      }

      return eventType
    },
    usersDropdownOptions() {
      if (!this.$store.getters.getCurrentRoom) return [];
      const users = this.$store.getters.getUsers;
      return users.map((item) => {
        let optionValue = item.phone;
        optionValue +=  item.last_name ? ` ${item.last_name}` : '';
        optionValue +=  item.first_name ? ` ${item.first_name}` : '';
        optionValue +=  item.note ? ` ${item.note}` : '';
        return {id: item.id, name: optionValue, discount: item.discount};
      });
    },
    isOrderPossible() {
      if (!this.$store.getters.getCurrentRoom) return null;
      if (this.$store.getters.getIsAdmin) {
        return this.userForBooking.id && this.cart.selected;
      } else {
        return this.cart.selected !== '';
      }
    },
    bookingPriceTotal() {
      if (!this.$store.getters.getCurrentRoom) return null;
      return `${this.$store.getters.getPayObject.amount} грн`;
    }
  },

  methods: {
    eventTypePrice(id) {
      let filterResult = this.eventType.options.filter((el) => {
        return el.id === id
      })

      if (filterResult.length > 0) {
        return Number(filterResult[0].price)
      } else {
        return 0
      }
    },
    changeTypeReserve (e) {
      sessionStorage.setItem('type-reserve', e)
      let payObj = this.$store.getters.getPayObject;
      payObj.pay_type = parseInt(e)
      this.$store.commit('setPayObject', payObj)
      sessionStorage.setItem("pay-object", JSON.stringify(payObj));
    },
    changeEventType(e) {
      let payObj = this.$store.getters.getPayObject;
      const roomPrice = this.eventTypePrice(Number(e));
      payObj.event_type = Number(e);
      this.$store.dispatch('setBookingAmount', roomPrice);
      sessionStorage.setItem("pay-object", JSON.stringify(payObj));
    },
    changeComment(comment) {
      let payObj = this.$store.getters.getPayObject;
      payObj.comment = comment;
      this.$store.commit('setPayObject', payObj)
      sessionStorage.setItem("pay-object", JSON.stringify(payObj));
    },
     reserve () {
      if (!localStorage.getItem('token')) {
        this.$bvModal.show('modal-login-step-1')
        this.$store.commit('setReserveAfterLogin', true)
      } else {
        this.$analytics.logEvent('booking',
          {
            date: this.moment().format("DD.MM.YYYY"),
            time: this.moment().format("HH:MM")
          }
        )
        this.$store.dispatch('BOOKINGS_PAY', this.$store.getters.getPayObject)
      }
    },
    selectUserForBooking(selection) {
      let payObj = this.$store.getters.getPayObject;
      this.userForBooking = selection;
      payObj.user_for_booking = selection;
      payObj.discount = selection.discount ?? 0;
      const roomPrice = this.eventTypePrice(payObj.event_type);
      this.$store.commit('setPayObject', payObj);
      this.$store.commit('setUserDiscount', payObj.discount);
      this.$store.dispatch('setBookingAmount', roomPrice);

    },
    getUserDropdownValues(keyword) {
      const pattern = /^[a-zа-я0-9іїєґ\ʼ\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~\@\. ]+$/i;

        if (keyword != '') {
          if (pattern.test(keyword)) {
            this.searchText = keyword;
            this.searchTextMemory = keyword;
            let val = keyword;

            if (val.length > 1) {
              this.$store.dispatch('USERS_SEARCH', encodeURIComponent(val));
              this.$store.commit('setSearchText', val);
            }

            if (val === '' || val.length < 2) {
              this.$store.dispatch('USERS_SEARCH', encodeURIComponent(''));
              this.$store.commit('setSearchText', '');
            }
          } else {
            this.searchText = this.searchTextMemory;
          }
        } else {
          this.clearSearch();
        }
    },
    clearSearch() {
      this.searchText = '';
      this.searchTextMemory = '';
      this.$store.dispatch('USERS_SEARCH', this.searchText);
      this.$store.commit('setSearchText', this.searchText);
    },
  },
  mounted() {
    if (sessionStorage.getItem('current-room-id')) {
      this.$store.commit("setCurrentRoom", this.$store.getters.getRoom(parseInt(sessionStorage.getItem('current-room-id'))));
    }
    if (sessionStorage.getItem('current-room-id') && sessionStorage.getItem('current-room-period')) {
      let currentRoom = {
        id: parseInt(sessionStorage.getItem('current-room-id')),
        period: sessionStorage.getItem('current-room-period'),
        day: sessionStorage.getItem('current-room-day'),
      };
      this.$store.commit("setCurrentRoom", currentRoom);
    }
    if (sessionStorage.getItem('storePeriod')) {
      this.$store.commit('SET_PERIOD', parseFloat(sessionStorage.getItem('storePeriod')))
    }

    if (sessionStorage.getItem('type-reserve')) {
      this.cart.selected = sessionStorage.getItem('type-reserve')
    } else {
      this.cart.selected = this.$store.getters.getIsAdmin ? '2' : '';
    }

    if (this.$store.getters.getIsAdmin) {
      this.$store.dispatch('USERS_GET');
    }
  },
  updated() {
    if (sessionStorage.getItem('type-reserve')) {
      this.cart.selected = sessionStorage.getItem('type-reserve')
    } else {
      this.cart.selected = null
    }
  },
  beforeDestroy() {
    this.$store.commit('setUserDiscount', 0);
    sessionStorage.removeItem("pay-object");

    if (this.$store.getters.getIsAdmin) {
      this.clearSearch();
    }
  }
}
</script>

<style lang="scss">
.booking_info-block-name {
  font-size: 12px !important;
}

.booking_info-block-value {
  font-size: 15px !important;
  white-space: nowrap;
  text-transform: capitalize;
}
.cart {
  border-left: 1px solid #e0e0e0;
  padding-left: 1rem;

  * {
    outline: none !important;
  }

  min-height: calc(100vh - 146px);
  display: flex;
  flex-direction: column;

  .booking_info-wrap {
    flex: 1;
  }

  .field-comment {
    margin-bottom: 20px;
  }

  .cart-room-info {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    #collapse-1 {
      overflow: auto;
      max-height: 210px;
    }
  }

  .users-list {
    margin: 20px 0 ;

    .dropdown {
      .dropdown-content {
        z-index: 10 !important;
      }
    }
  }
}
</style>
